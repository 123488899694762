import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {ReactComponent as LeftBg} from '../../../assets/images/login-bgleft.svg';
import {ReactComponent as RightBg} from '../../../assets/images/login-bg-right.svg';
import LokiLogo from "../../../assets/images/logo.svg"
import styled from "styled-components";
import {t} from "i18next";
import LokiSecondaryButton from "../../../components/common/Buttons/LokiSecondary";
import colors from "../../../assets/styles/colors";
import {toast} from "react-toastify";
import BackLink from "../../../components/common/Buttons/BackLink";
import {sendResetEmail} from "../../../services/firebase/firebaseService";

const FullscreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`

const PasswordResetPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [sendButtonEnabled, setSendButtonEnabled] = useState(true);

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setEmailError(t('error_invalid_email'));
            return;
        }

        setEmailError('');

        try {
            setSendButtonEnabled(false)
            await sendResetEmail(email)
            toast.success(t('password_reset_success_message'));
            navigate('/sign-in');
        } catch (error) {
            setSendButtonEnabled(true)
            console.log(error)
            toast.error(t(error.code || 'general_error'))
        }
    };


    function onLogoClick() {
        navigate("/")
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (emailError) {
            setEmailError('');
        }
    };

    return (
        <FullscreenContainer>
            <LeftBg className="position-absolute left bottom-0"/>
            <RightBg className="position-absolute end-0 top" style={{top: -2}}/>
            <Container component="main" maxWidth="xs" sx={{zIndex: 999}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Box sx={{display: 'flex', width:'100%', justifyContent: 'flex-start', mt:6}}>
                        <BackLink/>
                    </Box>
                    <img onClick={onLogoClick} src={LokiLogo}
                         style={{
                             borderRadius: 22,
                             marginBottom: "1rem",
                             marginTop: "2rem",
                             cursor: "pointer",
                             width: '8rem'
                         }}
                         alt="Lokì logo"/>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{background: `${colors.backgroundColor}`, borderRadius: 1}}
                            id="email"
                            label={t('email_address')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={handleEmailChange}
                            error={!!emailError}
                            helperText={emailError}
                        />
                        <LokiSecondaryButton
                            onClick={handleSubmit}
                            disabled={!sendButtonEnabled}
                            style={{
                                marginTop: "2rem",
                                marginBottom: "2rem"
                            }}>{t('reset_password')}</LokiSecondaryButton>
                    </Box>
                </Box>
            </Container>
        </FullscreenContainer>
    );
};

export default PasswordResetPage;
