import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LokiLogo from "../../../assets/images/logo.svg"
import styled from "styled-components";
import {t} from "i18next";
import colors from "../../../assets/styles/colors";
import sessionService from "../../../services/api/session/sessionService";
import {toast} from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import {
    getCurrentUserIdToken,
    updateDisplayName,
    updateProfileAvatar,
    verifyClaims
} from "../../../services/firebase/firebaseService";
import PasswordStrengthBar from "react-password-strength-bar";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import LokiSecondaryRoundedButton from "../../../components/common/Buttons/LokiSecondaryRounded";
import LokiLoader from "../../../components/common/Loader/LokiLoader";

const FullscreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`

const ClickableSpan = styled.span`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    cursor: pointer;
    color: ${colors.primaryColor};

    &:hover {
        transition: all 0.2s ease-in-out;
        opacity: 0.5;
    }
`

const TCText = styled.p`
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-top: 0;
    text-align: center;
    color: ${colors.textColor};
`

const RegisterPage = () => {
    const {logout} = useAuth()
    const {register} = useAuth();
    const {setVerifiedWithBackend} = useAuth();

    const navigate = useNavigate();
    const [passwordScore, setPasswordScore] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [formValues, setFormValues] = useState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const validate = () => {
        let errors = {};
        errors.email = validateEmail(formValues.email) ? '' : t('error_invalid_email');
        errors.password = formValues.password ? '' : t('error_field_mandatory');
        errors.password = passwordScore >= 3 ? '' : t('error_password_too_weak');
        errors.confirmPassword = formValues.confirmPassword === formValues.password ? '' : t('error_password_match');
        errors.displayName = formValues.displayName ? '' : t('error_field_mandatory');

        setFormErrors(errors);
        return Object.values(errors).every(x => x === "");
    };

    const getSessionCreateDto = async () => {
        const firebaseToken = await getCurrentUserIdToken();

        const sessionCreateDto = {
            firebaseToken: firebaseToken
        };

        return sessionCreateDto
    }

    const registerToFirebase = async () => {
        try {
            await register(formValues.email, formValues.password);
            await updateDisplayName(formValues.displayName)

            const unknownAvatarUrl = process.env.REACT_APP_UNKNOWN_AVATAR_URL;
            await updateProfileAvatar(unknownAvatarUrl)

            return true
        } catch (error) {
            console.log(error)
            toast.error(t(error.code || 'general_error'))
            console.error("Firebase error: ", error);
            return false
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validate()) {
            setIsLoading(true)
            try {
                if (!await registerToFirebase()) {
                    setIsLoading(false)
                    return
                }

                const sessionCreateDto = await getSessionCreateDto()
                await sessionService.registerSession(sessionCreateDto)
                await verifyClaims()

                await setVerifiedWithBackend(true)
                navigate("/email")
                toast.success(t('sign_up_success_message'));
                console.log(formValues);
                setIsLoading(false)

            } catch (error) {
                toast.error(t(error.code || 'general_error'))
                console.error("Backend error while logging in: ", error);
                await logout()
                setIsLoading(false)
            }
        }
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormValues({...formValues, [name]: value});
    };

    function onLogoClick() {
        navigate("/")
    }

    if (isLoading) {
        return (
            <FullscreenContainer>
                <Container component="main" maxWidth="xs"
                           sx={{
                               zIndex: 999,
                               borderRadius: '12px',
                               boxShadow: 12,
                               marginTop: "5rem",
                               mt: 6,
                               marginRight: '16px',
                               marginLeft: '16px'
                           }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingBottom: '10rem'
                        }}
                    >
                        <img onClick={onLogoClick} src={LokiLogo} style={{
                            borderRadius: 22,
                            marginBottom: "1rem",
                            marginTop: "1rem",
                            cursor: "pointer",
                            width: '5rem'
                        }} alt="Lokì logo"/>

                        <LokiLoader/>
                    </Box>
                </Container>
            </FullscreenContainer>
        )
    }

    return (
        <FullscreenContainer>
            <Container component="main" maxWidth="xs"
                       sx={{
                           zIndex: 999,
                           borderRadius: '12px',
                           boxShadow: 12,
                           marginTop: "5rem",
                           marginRight: '16px',
                           marginLeft: '16px',
                           mt: 6
                       }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img onClick={onLogoClick} src={LokiLogo} style={{
                        borderRadius: 22,
                        marginBottom: "1rem",
                        marginTop: "1rem",
                        cursor: "pointer",
                        width: '5rem'
                    }} alt="Lokì logo"/>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            id="displayName"
                            label={t('display_name')}
                            name="displayName"
                            autoComplete="displayName"
                            inputProps={{
                                maxLength: 100,
                            }}
                            autoFocus
                            value={formValues.displayName}
                            onChange={handleChange}
                            error={!!formErrors.displayName}
                            helperText={formErrors.displayName}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            id="email"
                            label={t('email_address')}
                            name="email"
                            autoComplete="email"
                            value={formValues.email}
                            onChange={handleChange}
                            error={!!formErrors.email}
                            helperText={formErrors.email}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            name="password"
                            label={t('password')}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={formValues.password}
                            onChange={handleChange}
                            error={!!formErrors.password}
                            helperText={formErrors.password}
                        />
                        <PasswordStrengthBar
                            password={formValues.password}
                            shortScoreWord={t('too_short')}
                            scoreWords={[t('very_weak'), t('weak'), t('medium'), t('strong'), t('very_strong')]}
                            onChangeScore={(score, feedback) => {
                                setPasswordScore(score)
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            sx={{
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            name="confirmPassword"
                            label={t('confirm_password')}
                            id="confirm_password"
                            value={formValues.confirmPassword}
                            onChange={handleChange}
                            error={!!formErrors.confirmPassword}
                            helperText={formErrors.confirmPassword}
                        />
                        <LokiSecondaryRoundedButton
                            onClick={handleSubmit}
                            style={{
                                marginTop: "2rem",
                                marginBottom: "2rem"
                            }}>{t('sign_up')}</LokiSecondaryRoundedButton>
                        <TCText>{t('terms_and_conditions_accept_text').replace('{buttonText}', t('sign_up'))}
                            <ClickableSpan
                                onClick={() => window.open('/legal', '_blank')}>{t('terms_and_conditions')}</ClickableSpan> {t('and')}
                            <ClickableSpan
                                onClick={() => window.open('/legal', '_blank')}>{t('privacy_policy')}</ClickableSpan>.
                        </TCText>
                    </Box>
                </Box>
            </Container>
        </FullscreenContainer>
    );
};

export default RegisterPage;
