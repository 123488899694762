import React from "react";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import {FaTimes} from "react-icons/fa";
import {Link} from 'react-router-dom';
import {t} from "i18next";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";
import {Divider} from "@mui/material";

const SidebarContainer = styled.aside`
    position: fixed;
    width: 100%;
    height: 100%;
    background: ${colors.backgroundColor};
    display:  ${({open}) => (open ? 'grid' : 'none')};
    align-items: center;
    left: 0;
    transition: 0.2s ease-in-out;
    opacity: ${({open}) => (open ? '100%' : '0')};
    z-index: ${({open}) => (open ? '999' : '-999')};
`
const CloseIcon = styled(FaTimes)`
    color: ${colors.primaryColor}
`

const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 0.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

const SidebarWrapper = styled.div`
    color: ${colors.backgroundColor};
`

const SidebarMenu = styled.ul`
    display: grid;
    text-align: center;
`

const SidebarLink = styled(Link)`
    padding-right: 16px;
    padding-bottom: 6px;
    font-size: 1.5rem;
    text-decoration: none;
    text-align: center;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: ${colors.textColor};

    &:hover {
        color: ${colors.primaryColor};
        transition: 0.2s ease-in-out;
    }
`

const SideBtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
`

const SidebarRoute = styled(Link)`
    width: 100%;
    text-align: center;
    border-radius: 4px;
    background: ${colors.buttonBackground};
    white-space: nowrap;
    padding: 10px 22px;
    color: ${colors.textColor};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border: 1px solid ${colors.primaryColor}; // Add border here
    outline: none;
    font-weight: 500;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${colors.primaryColor};
        color: ${colors.buttonBackground};
        border-color: ${colors.buttonBackground};
    }
`

const Sidebar = ({isOpen, toggle}) => {
    const {isAuthenticated, logout} = useAuth();

    const handleLogout = async () => {
        try {
            await logout()
            toast.info(t('logout_toast'))
        } catch (error) {
            console.log(error)
            toast.error(t(error.code || 'general_error'))
        }
    }

    return (
        <SidebarContainer open={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/">{t('navbar_home')}</SidebarLink>
                    <SidebarLink to="/search">{t('navbar_adopt')}</SidebarLink>
                    <SidebarLink to="/shelters">{t('navbar_shelters')}</SidebarLink>
                    <SidebarLink to="/missing">{t('navbar_missing')}</SidebarLink>
                    <SidebarLink as="a" href={"https://prieglauda.loki.lt"} target="_blank" rel="noopener noreferrer">{t('navbar_shelter')}</SidebarLink>
                </SidebarMenu>
                <Divider sx={{mb:6, mt:3, mr:3, ml:3}}/>
                {isAuthenticated() ? (
                        <SideBtnWrapper>
                            <SidebarRoute to="/profile">{t('my_profile')}</SidebarRoute>
                            <SidebarRoute to="/new-report">{t('create_report')}</SidebarRoute>
                            <SidebarRoute to="/help">{t('help')}</SidebarRoute>
                            <SidebarRoute onClick={handleLogout}>{t('logout')}</SidebarRoute>
                        </SideBtnWrapper>
                    ) :
                    (<SideBtnWrapper>
                        <SidebarRoute to="/sign-in">{t('sign_in')}</SidebarRoute>
                    </SideBtnWrapper>)
                }

            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar