import React, {useCallback, useEffect, useState} from 'react';
import NavBar from "../../../components/common/NavigationBar/NavBar";
import Banner from "../../../components/features/Feed/Banner"
import SearchBarWithButton from "../../../components/features/Search/SearchBarWithButton";
import ListingSection from "../../../components/features/Listing/ListingSection";
import feedService from "../../../services/api/feed/feedService";
import Footer from "../../../components/common/Footer/Footer";
import {useNavigate} from "react-router-dom";
import ListingSkeleton from "../../../components/features/Listing/ListingSkeleton";
import FeaturedSection from "../../../components/features/FeaturedListing/FeaturedSection";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import {Fade} from "@mui/material";
import {toast} from "react-toastify";
import {t} from "i18next";
import styled from "styled-components";

const Spacer = styled.div`
    height: 5rem;

    @media (max-width: 599px) {
        height: 0rem;
    }
`

const TYPE_FEATURED = "Featured";
const TYPE_CATEGORY = "Category";

const FeedPage = ({setSearchQuery}) => {
    const navigate = useNavigate();

    const handleSearchClick = useCallback(() => {
        navigate(`/search`);
    }, [navigate]);

    const {isLoading, showLoadingUI, setIsLoading} = useDebouncedLoading(true);
    const [featuredItem, setFeaturedItem] = useState([])
    const [categoryItems, setCategoryItems] = useState([])

    useEffect(() => {
        const fetchFeed = async () => {
            try {
                setIsLoading(true)
                const data = await feedService.getFeed();
                const featuredItem = data.find((feed) => feed.type === TYPE_FEATURED);
                const categoryItems = data.filter((feed) => feed.type === TYPE_CATEGORY);
                setFeaturedItem(featuredItem)
                setCategoryItems(categoryItems)
                setIsLoading(false);
            } catch (err) {
                console.log(err)
                toast.error(t(err.code || 'general_error'))
                setIsLoading(false);
            }
        };

        fetchFeed();
    }, [setIsLoading]);

    return (
        <>
            <div style={{paddingBottom: '2rem'}}>
                <NavBar/>
                <Banner/>
                <br/>
                <Fade in={true}>
                    <div style={{width: '100%'}}>
                        <SearchBarWithButton
                            handleChange={(event) => setSearchQuery(event.target.value)}
                            onSearchClick={handleSearchClick}
                        />
                    </div>
                </Fade>
                <Spacer/>
                {showLoadingUI ?
                    <Fade in={true} timeout={300}>
                        <div>
                            <ListingSkeleton showTitleSkeleton={false}/>
                            <ListingSkeleton showTitleSkeleton={true}/>
                            <Footer/>
                        </div>
                    </Fade>
                    :
                    <div>
                        {featuredItem && (
                            <FeaturedSection key={featuredItem.id ? featuredItem.id : 'featured-item'}
                                             feedItem={featuredItem}/>
                        )}
                        {categoryItems.map((feed, index) => (
                            <ListingSection key={feed.id ? feed.id : `category-item-${index}`} feedItem={feed}/>
                        ))}
                    </div>
                }
            </div>
            {isLoading ? <div/> : <Footer/>}
        </>
    );
};

export default FeedPage;