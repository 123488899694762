import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LokiLogo from "../../../assets/images/logo.svg"
import styled from "styled-components";
import {t} from "i18next";
import colors from "../../../assets/styles/colors";
import {toast} from 'react-toastify';
import useAuth from "../../../hooks/useAuth";
import {getCurrentUserIdToken, verifyClaims} from "../../../services/firebase/firebaseService";
import sessionService from "../../../services/api/session/sessionService";
import {Divider, IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Link from "@mui/material/Link";
import LokiSecondaryRoundedButton from "../../../components/common/Buttons/LokiSecondaryRounded";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import GoogleButton from "../../../components/common/Buttons/GoogleButton";


const FullscreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 2rem;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 0.8s ease-in-out;
`


const Text = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 8px;
    color: ${colors.textColor};
    text-align: center;
    padding-bottom: 4px;
`

const HighlightedSpan = styled.span`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.secondaryColor};
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        opacity: 0.5;
    }
`

const LoginPage = () => {
    const {login, signInWithGoogle, setVerifiedWithBackend} = useAuth();

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [values, setValues] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const getSessionLoginDto = async () => {
        const firebaseToken = await getCurrentUserIdToken();

        return {
            firebaseToken: firebaseToken
        }
    }

    const loginToFirebase = async () => {
        try {
            await login(values.email, values.password);
            return true
        } catch (error) {
            toast.error(t(error.code || 'general_error'))
            setValues({email: "", password: ""})
            console.error("Firebase error: ", error);
            return false
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            setIsLoading(true)
            try {
                if (!await loginToFirebase()) {
                    setIsLoading(false)
                    return
                }

                const sessionLoginDto = await getSessionLoginDto()
                await sessionService.loginSession(sessionLoginDto)

                await verifyClaims()
                await setVerifiedWithBackend(true)

                navigate("/")
                toast.success(t('sign_in_success_message'));
                setIsLoading(false)
            } catch (error) {
                let reactorException = error.response?.data
                console.log(reactorException)
                if (reactorException?.code === 'R6') {
                    navigate("/kyc")
                    return
                }

                setValues({email: "", password: ""})
                console.error("Backend error while logging in: ", error);
                setIsLoading(false)
            }
        }
    };
    const handleGoogleLogin = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        try {
            const googleResponse = await signInWithGoogle()
            console.log(googleResponse)
            if (!googleResponse) {
                setIsLoading(false)
                return
            }

            const sessionLoginDto = await getSessionLoginDto()
            await sessionService.loginSession(sessionLoginDto)

            await verifyClaims()
            await setVerifiedWithBackend(true)

            navigate("/")
            toast.success(t('sign_in_success_message'));
            setIsLoading(false)
        } catch (error) {
            let reactorException = error.response?.data
            console.log(reactorException)
            if (reactorException?.code === 'R6') {
                navigate("/kyc")
                return
            }
            toast.error(t(error.code))
            setValues({email: "", password: ""})
            console.error("Backend error while logging in: ", error);
            setIsLoading(false)
        }
    };

    function onLogoClick() {
        navigate("/")
    }

    function onForgotPasswordClick() {
        navigate("/reset-password")
    }

    function onRegisterClick() {
        navigate("/sign-up")
    }

    const isValidEmail = (email) => {
        //TODO: Move this to separate validations file
        return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
    };

    const validate = () => {
        let tempErrors = {...errors};
        tempErrors.email = isValidEmail(values.email) ? '' : t('error_invalid_email');
        tempErrors.password = values.password ? '' : t('error_field_mandatory');
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setValues({...values, [name]: value});
    };

    if (isLoading) {
        return (
            <FullscreenContainer>
                <Container component="main" maxWidth="xs"
                           sx={{
                               zIndex: 999,
                               borderRadius: '12px',
                               boxShadow: 12,
                               marginTop: "5rem",
                               marginRight: '16px',
                               marginLeft: '16px'
                           }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '10rem'
                        }}
                    >
                        <img onClick={onLogoClick} src={LokiLogo}
                             style={{
                                 borderRadius: 22,
                                 marginBottom: "1rem",
                                 cursor: "pointer",
                                 width: '5rem'
                             }}
                             alt="Lokì logo"/>

                        <LokiLoader/>
                    </Box>
                </Container>
            </FullscreenContainer>
        )
    }

    return (
        <FullscreenContainer>
            <Container component="main" maxWidth="xs"
                       style={{
                           boxShadow: '0px 0px 12px rgba(0,0,0,0.2)',
                       }}
                       sx={{
                           zIndex: 999,
                           borderRadius: '12px',
                           marginTop: "5rem",
                           marginRight: '16px',
                           marginLeft: '16px',
                           marginBottom: '2rem'
                       }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img onClick={onLogoClick} src={LokiLogo}
                         style={{
                             borderRadius: 22,
                             marginBottom: "1rem",
                             cursor: "pointer",
                             width: '5rem'
                         }}
                         alt="Lokì logo"/>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            id="email"
                            label={t('email_address')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={values.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            sx={{
                                borderRadius: '12px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                }
                            }}
                            name="password"
                            label={t('password')}
                            id="password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            autoComplete="current-password"
                            value={values.password}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 0}}>
                            <Link onClick={onForgotPasswordClick} variant="body2" sx={{cursor: "pointer"}}>
                                {t('forgot_password')}
                            </Link>
                        </Box>

                        <LokiSecondaryRoundedButton onClick={handleSubmit}
                                                    style={{
                                                        marginTop: "2rem",
                                                        marginBottom: "1rem"
                                                    }}>
                            {t('sign_in')}
                        </LokiSecondaryRoundedButton>

                        <Divider flexItem sx={{mt: 1, mb: 3}}>
                            <p style={{margin: 0}}>{t('or')}</p>
                        </Divider>

                        <GoogleButton onClick={handleGoogleLogin}/>
                        <br/>

                    </Box>
                </Box>
            </Container>
            <Text>{t('dont_have_account')}<HighlightedSpan
                onClick={onRegisterClick}>{t('create_account')}</HighlightedSpan></Text>
        </FullscreenContainer>
    );
};


export default LoginPage;
