import React, {useState} from 'react';
import {FaBars} from 'react-icons/fa';
import {NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';
import colors from "../../../assets/styles/colors";
import Sidebar from "./Sidebar";
import {t} from "i18next";
import useAuth from "../../../hooks/useAuth";
import {Fade, Menu} from "@mui/material";
import {toast} from "react-toastify";
import UserMenuItems from "./UserMenuItems";
import UserAvatar from "./UserAvatar";
import Container from "@mui/material/Container";

const Nav = styled.nav`
    background: ${colors.backgroundColor};
    height: 80px;
    display: flex;
    justify-content: space-between;
    z-index: 10;
`;

const NavLink = styled(Link)`
    color: ${colors.textColor};
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    padding: 0 1rem;
    height: 100%;
    font-weight: 500;
    cursor: pointer;

    &.active {
        color: ${colors.secondaryColor};
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        color: ${colors.secondaryColor};
    }
`;

const HideInMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        display: none;
    }
`

const Bars = styled(FaBars)`
    display: none;
    color: ${colors.textColor};

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const StyledImage = styled.img`
    border-radius: 16px;
    width: 48px;
    aspect-ratio: 1;
`;

const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-right: 24px;
    border-color: #333333;


    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: ${colors.buttonBackground};
    padding: 6px 16px;
    color: ${colors.textColor};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    border: 1px solid ${colors.divider};
    outline: none;
    margin-left: 24px;
    font-weight: 500;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${colors.primaryColor};
        color: ${colors.buttonBackground};
        border-color: ${colors.buttonBackground};
    }
`;

const Navbar = () => {
    const {isAuthenticated, logout, isLoading} = useAuth();
    const [isOpen, setIsOpen] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    if (isLoading()) {
        return <div style={{height: '80px'}}/>;
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            handleClose()
            await logout()
            toast.info(t('logout_toast'))
        } catch (error) {
            console.log(error)
            toast.error(t(error.code || 'general_error'))
        }
    }

    const toggle = () => {
        handleClose()
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>

            <Container maxWidth="lg">
                <Fade in={true} timeout={300}>
                    <Nav>
                        <NavLink to='/'>
                            <StyledImage src={require('../../../assets/images/logo.png')} alt='logo'/>
                        </NavLink>
                        <Bars onClick={toggle}/>
                        <NavMenu>
                            <NavLink to='/'>
                                {t('navbar_home')}
                            </NavLink>
                            <NavLink to='/search'>
                                {t('navbar_adopt')}
                            </NavLink>
                            <NavLink to='/shelters'>
                                {t('navbar_shelters')}
                            </NavLink>
                            <NavLink to='/missing'>
                                {t('navbar_missing')}
                            </NavLink>
                            <NavLink as="a" href={"https://prieglauda.loki.lt"} target="_blank"
                                     rel="noopener noreferrer">
                                {t('navbar_shelter')}
                            </NavLink>
                        </NavMenu>
                        {isAuthenticated() ? (
                                <HideInMobile>
                                    <UserAvatar onClick={handleClick}/>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        slotProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 1px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                            },
                                        }}
                                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                    >
                                        <UserMenuItems handleClose={handleClose} onLogout={handleLogout}/>
                                    </Menu>

                                </HideInMobile>
                            ) :
                            (<NavBtn>
                                <NavBtnLink to='/sign-in'>{t('sign_in')}</NavBtnLink>
                            </NavBtn>)
                        }
                    </Nav>
                </Fade>
            </Container>
        </>
    );
};

export default Navbar;