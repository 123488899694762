import LokiLogo from "../../../assets/images/logo.svg";
import EmailConfirmation from "../../../assets/images/email_confirmation.png";
import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import useAuth from "../../../hooks/useAuth";
import {reload, sendVerificationEmail} from "../../../services/firebase/firebaseService";
import {useNavigate} from "react-router-dom";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import {HashLoader} from "react-spinners";
import {Fade} from "@mui/material";

const SendButton = styled.button`
    border-radius: 8px;
    align-self: center;
    outline: none;
    border: none;
    font-family: 'Inter', sans-serif;
    background: ${colors.black};
    color: ${colors.backgroundColor};
    font-size: 0.9rem;
    font-weight: 500;
    padding: 12px 16px;

    @media (min-width: 768px) {
        &:hover {
            transition: all 0.2s ease-in-out;
            background: ${colors.primaryColor};
        }
    }
`
const EmailConfirmationPage = () => {

    const {isLoading, user} = useAuth();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reload()
    }, []);

    if (isLoading()) {
        return <LokiLoader/>
    }

    if (user?.emailVerified) {
        navigate('/')
        return
    }

    const onSendClick = async () => {
        setLoading(true)
        if (!await sendVerificationEmail()) {
            setLoading(false)
            return
        }

        setLoading(false)
        navigate("/")
    }

    return (
        <Fade in={true} timeout={600}>
            <Container maxWidth="md">
                <img src={LokiLogo}
                     loading="eager"
                     onClick={() => navigate("/")}
                     style={{
                         borderRadius: 22,
                         marginBottom: "1rem",
                         cursor: 'pointer',
                         marginTop: '1rem',
                         width: '4rem'
                     }}
                     alt="Lokì logo"/>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '10rem'
                    }}
                >
                    <img src={EmailConfirmation}
                         loading="eager"
                         style={{
                             borderRadius: 22,
                             marginBottom: "1rem",
                             cursor: "pointer",
                             width: '20rem'
                         }}
                         alt="Lokì logo"/>
                    <Typography variant="h5" sx={{textAlign: 'center'}}>
                        <b>{t('email_confirmation_title')}</b>
                    </Typography>
                    <Typography variant="h7" sx={{textAlign: 'center'}}>
                        {t('email_confirmation_subtitle').replace("{email}", user?.email || "")}
                    </Typography>

                    <br/>
                    <br/>

                    {loading ? <HashLoader color={colors.primaryColor} size={30}/> :
                        <SendButton onClick={onSendClick}>{t('verify_email')}</SendButton>
                    }
                </Box>
            </Container>
        </Fade>
    );
}

export default EmailConfirmationPage